import Container from 'react-bootstrap/Container'
import Collapse from 'react-bootstrap/Collapse'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import {ArrowLeftShort, ChevronDown, ChevronRight, ChevronCompactDown, PencilSquare, Intersect, ArrowDownCircle, CaretDownFill} from 'react-bootstrap-icons'
//import { Input } from 'semantic-ui-react'
import styled from 'styled-components'
import { DownloadButton } from '../../document/style'

const mobileSize = "768px";

export const ModalContainer = styled.div`
  position: fixed;
  background-color: #222222;
`;

export const BeContainer = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;

  @media (max-width: ${mobileSize}) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const BaseContainer = styled.div`
  border-radius: 3px;
  width: 100%;
  margin: 5px 0px;
`;

export const DocumentInfoContainer = styled(BaseContainer)`
  background-color: #FFFFFF;
  box-shadow: 3px 4px 5px #C9C9C9;
`;

export const InactiveContainer = styled(BaseContainer)`
  background-color: #F6F6F6;
  border: solid 1px #C2C2C2;
  padding: 10px 15px;
`;

export const RowContainer = styled(Row)`
  margin-top: 8px;
`;

export const ColContainer = styled(Col)`
  
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 15px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
`;

export const TitleContainer = styled.div`
  margin: 10px 0px;
  border-left: solid 5px #2680EB;
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  padding-top: 5px;
  padding-left: 10px;
`;

export const DocumentTitle = styled.div`
  font-size: 20px;
`;

export const DocumentDoneTitle = styled.div`
  font-size: 20px;
  color: #38b000;
`;

export const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 15px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const StandardButton = styled(Link)`
  width: 80px;
  height: 40px;
  line-height: 36px;
  background-color: #FFFFFF;
  border: solid 1px #A2A2A2;
  color: #707070;
  border-radius: 3px;
  text-align: center;
  margin-top: 5px;
`;

export const DownloadPdfButton = styled(Link).attrs(props => ({
  href: props.href
  }))`
  width: 80px;
  height: 40px;
  line-height: 36px;
  background-color: #FFFFFF;
  border: solid 1px #A2A2A2;
  color: #707070;
  border-radius: 3px;
  text-align: center;
  margin-top: 5px;
`;

export const BackButton = styled(StandardButton)`
  
`;

export const EditButton = styled(StandardButton)`
  margin-right: 8px;
`;

export const BackIcon = styled(ArrowLeftShort)`
  width: 30px;
  height: 30px;
`;

export const EditIcon = styled(PencilSquare)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const FlexiText = styled.span`
  @media (max-width: 450px) {
    display: none;
  }
`;

export const DocumentInfo = styled.div`
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${mobileSize}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ItemContainer = styled.span`
  margin-right: 20px;
`;

export const Label = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

export const Notification = styled.span`
  font-weight: lighter;
  margin-right: 5px;
  color: #ff9999;
  text-align: center;
`;


export const Text = styled.span.attrs(props => ({
    mobileDisplay: props.mobileDisplay
  }))`
  font-size: 12px;
  
  @media (max-width: 450px) {
    display: ${props => props.mobileDisplay || ""};
  }
`;

export const DocumentList = styled.div`
  font-size: 12px;
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  margin-top: 3px;
  margin-bottom: 10px;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px #B8B8B8;
`;

export const THead = styled.thead`
`;

export const TBody = styled.tbody`
`;

export const Tr = styled.tr.attrs(props => ({
  textColor: props.textColor
  }))`
  color: ${props => props.textColor};
  border: solid 1px #B8B8B8;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:nth-child(even) {
    background-color: #F9F9F9;
  }
  &:hover {
    background-color: #FFFAD9;
  }
`;

export const Th = styled.th.attrs(props => ({
    mobileDisplay: props.mobileDisplay,
  }))`
  padding: 10px;
  font-weight: bold;
  background-color: #EEEEEE;
  text-align: left;
  border-collapse: collapse;

  @media (max-width: ${mobileSize}) {
    display: ${props => props.mobileDisplay || ""};
  }
}
`;

export const Td = styled.td.attrs(props => ({
    fontWeight: props.fontWeight,
    textColor: props.textColor,
    width: props.width,
    mobileDisplay: props.mobileDisplay
  }))`
  padding: 10px;
  font-weight: ${props => props.fontWeight};
  color: ${props => props.textColor};
  width: ${props => props.width || "auto"};

  @media (max-width: ${mobileSize}) {
    display: ${props => props.mobileDisplay || ""};
  }
`;

export const ViewButton = styled(Link)`
  height: 20px;
  padding: 5px 15px;
  border: dotted 1px #A2A2A2;
  color: #2680EB;
  background-color: #FFFFFF;
  border-radius: 3px;
  text-align: center;
`;

export const DescriptionContainer = styled.div`
  margin: 0px 15px 10px 15px;
`;

export const ApprovalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const DescriptionLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
`;


export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border: solid 1px #B8B8B8;
  border-radius: 3px;
`;

export const HeadContainer = styled.div`
  cursor: pointer;
  font-size: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #515151;
  padding: 3px 0px;
`;

export const ApprovalIcon = styled(Intersect)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const ApprovalIconBlue = styled(ApprovalIcon)`
  color: #2680EB;
`;

export const ApprovalIconGray = styled(ApprovalIcon)`
  color: #818181;
`;

export const BodyContainer = styled(Collapse)`  
  font-size: 12px;
`;

export const HeadTitle = styled.div`
  font-weight: bold;
`;

export const ActivityStatus = styled.div.attrs(props => ({
  backgroundColor: props.backgroundColor,
  }))`
  width: 80px;
  height: 20px;
  line-height: 20px;
  margin-left: auto;
  color: #FFFFFF;
  background-color: ${props => props.backgroundColor};
  font-size: 12px;
  border-radius: 3px;
  text-align: center;
`;

export const DownArrow = styled(ChevronDown)`
  font-weight: bold;
  width: 16px;
  height: 16px;
  margin-left: 5px;
`;

export const RightArrow = styled(ChevronRight)`
  font-weight: bold;
  width: 16px;
  height: 16px;
  margin-left: 5px;
`;

export const ActiveContainer = styled(BaseContainer)`
  background-color: #FFFFFF;
  box-shadow: 3px 4px 5px #C9C9C9;
`;

export const ActiveContentContainer = styled.div`
  margin: 10px 0px;
  padding: 0px 16px 0px 10px;
  border-left: solid 5px #2680EB;
  display: flex;
  flex-direction: column;
`;

export const SigingButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const DefaultButton = styled.button`
  padding: 8px 10px;
  color: #FFFFFF;
  border-width: 0px;
  border-radius: 3px;
  min-width: 80px;
  min-height: 40px;
`;

export const ApproveButton = styled(DefaultButton)`
  background-color: #17C177;
`;

export const DelegateButton = styled(DefaultButton)`
  background-color: #F3A83C;
  margin-left: 10px;
`;

export const RejectButton = styled(DefaultButton)`
  background-color: #D84B65;
  margin-left: 10px;
`;

export const CancelButton = styled(DefaultButton)`
  background-color: #A2A2A2;
`;

export const SubmitButton = styled(DefaultButton)`
  background-color: #2680EB;
`;

export const WorkflowArrowDown = styled(ChevronCompactDown)`
  width: 36px;
  height: 36px;
  color: #A2A2A2;
`;

export const RejectOption = styled.div.attrs(props => ({
    backgroundColor: props.backgroundColor,
    color: props.color,
  }))`
  border: dotted 1px #A2A2A2;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  color: ${props => props.color || "#515151"};
  font-weight: bold;
  background-color: ${props => props.backgroundColor || "#FFFFFF"};
  border-radius: 3px;
  cursor: pointer;
`;

export const Input = styled.input`
  padding: 5px 10px;
  border: solid 1px #A2A2A2;
  border-radius: 3px;
  width: 100%;
  min-height: 40px;
`;

export const SummaryContainer = styled.div`
  font-size: 12px;
  margin: 0px 15px 15px 15px;
`;

export const InfoContainer = styled.div.attrs(props => ({
  marginTop: props.marginTop,
}))`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${props => props.marginTop || "0px"};
`;

export const DelegateContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
