import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import searchIcon from '../../assets/images/icons/search22x22.png'

import ApprovalBase from './ApprovalBase';
import util, { isNull } from 'util';
import TableRow from '../../commons/tableInput';
import ErrorMessageModal from './ErrorMessageModal';
import LoadTemplateModal from './LoadTemplateModal';
import SaveTemplateModal from './SaveTemplateModal';

import {
  Table, THead, Tr, Th, Td, TBody, RemoveButton, FormContainer, Title, ApprovalDate, InfoContainer,
  Label, Select, Option, ApproveLevelContainer, InputContainer, Input, SearchboxContainer, SigingButtonBox, GreenButton, 
  RedButton, FormHeader, BackButton, LeftArrow, ComponentContainer, ViewButton, BlueButton, ApprovalIcon, TitleContainer,
  RemoveApprovalIcon, ContentContainer, ApprovalContentContainer, BlueBorderButton, ControlerCol, ControlerRow,
  SearchBoxContainer, AddButton, PlusIcon, ReSearchBox, GreenBorderButton, RedBorderButton, GrayBorderButton, TemplateSection, 
  LeftHeader, TemplateName, CustomLink, FormTitle, BoldLabel, CheckSquareIcon
  
} from './style';
import DocumnetModalForm from '../document/DocumentModalForm'
// import fetchData actions
import {
    fetchSigner,
    fetchSigners,
    fetchCompany,
    fetchGetApprovalCreate,
    fetchApprovalTemplates,
    getCreateApproval,
    fetchDocuments,
    createApprovalAction,
    updateApprovalAction,
    onClickUploadDocumentByModal,
    selectViewDocumentAction,
    selectDocumentAction,
    fetchFarmDatas,
    getGrainImageValidationAction,
  } from '../../store/actions';
import { NutFill } from 'react-bootstrap-icons';
import { formatMs } from '@material-ui/core';
// import {utilities} from '../commons';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
// const _today = formatDate(new Date());

class ApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalNumber: '',
      approvalName: '',
      priority: 'Medium',
      summary: "default summary",
      company: {},
      existingCheck: false,
      signers: [],
      supportDocs: [],
      approvalDoc: null,
      approvalLevels: [
        {level: 1, group: "Farm Data Signing", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
        {level: 2, group: "Transportation Signing", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
        // {level: 3, group: "Transport 2 Signing", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
        {level: 3, group: "Inspection Signing", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
        // {level: 5, group: "Overseas Port Signing", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
      ],  //default = 2 leve
      errMsg: '',
      grainQualityGrade: null,
      updatePermission: true,
      isUpdateApproval: false,
      showDocumentModal: false,
      showErrorMessageModal: false,
      showLoadTemplateModal: false,
      showSaveTemplateModal: false,
    };
    
    // this.props.onGetCreateApproval();
  }



  componentDidMount() {
    let currentUser = this.props.currentUser;
    if (currentUser) {
      // this is an update approval, load this value to the form
      if (!this.props.farmDatas) {
        this.props.onFetchFarmDatas();
      } else if (this.props.farmDatas.length == 0) {
        this.props.onFetchFarmDatas();
      }
      let approval = this.props.approval;
      if (approval) {
        let {isUpdateApproval} = this.state;
        isUpdateApproval = true;
        this.setState({isUpdateApproval});
        let {
          approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, company, stage, summary
        } = this.state;
        approvalName = approval.approvalName ? approval.approvalName : '';
        approvalNumber = approval.approvalNumber ? approval.approvalNumber : '';
        approvalLevels = approval.approvalLevels ? approval.approvalLevels : this.state.approvalLevels;
        priority = approval.priority ? approval.priority : '';
        supportDocs = approval.supportDocs ? approval.supportDocs : [];
        approvalDoc = approval.approvalDoc ? approval.approvalDoc : '';
        company = approval.company ? approval.company._id : '';
        stage = approval.stage ? approval.stage : '';
        summary = approval.summary ? approval.summary : '';
        // format date for approvalLevel
        for (var _index = 0; _index < approvalLevels.length; _index++){
          // approvalLevels[_index].deadline = this.formatDate(_today);
          if (approvalLevels[_index].deadline) {
            if (approvalLevels[_index].deadline.toString() != "") {
              approvalLevels[_index].deadline = this.formatDate(approvalLevels[_index].deadline);
            }
          }
          
        }
        this.setState({
          approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, company, stage, summary
        })
        // fetch company
        // let _today = new Date();
        // let {approvalLevels} = this.state;
        
        // this.setState({approvalLevels});
      } else {
        // fetch approvalNumber
        let _today = new Date();
        let _month = _today.getMonth() + 1;
        if (_month <= 9) {
        _month = '0' + _month.toString();
        }
        let _day = _today.getDate();
        if (_day <= 9) {
        _day = '0' + _day.toString();
        }
        let formatDate = _today.getFullYear() + _month + _day;
        let _nextNumber = formatDate + '1';
        let {approvalLevels, approvalNumber} = this.state;
        approvalNumber = this.props.approvalNumber ? this.props.approvalNumber : _nextNumber;
        for (var _index = 0; _index < approvalLevels.length; _index++){
          approvalLevels[_index].deadline = this.formatDate(new Date());
        }
        this.setState({approvalLevels, approvalNumber});
      }
      // 
      
      
      this.props.onFetchCompany(currentUser.company)
        // fetch documents, documentNames
      this.props.onFetchDocuments();
      // fetch signers/users
      this.props.onFetchSigners();
      // set permission for update/delete approval
      const userRole = currentUser.role;
      const editeApprovalRoles = ['admin', 'chief', 'ceo', 'director', 'accountChief', 'approving', 'representative', 'chaiman'];
      if (editeApprovalRoles.includes(userRole)) {
        let {updatePermission} = this.state;
        updatePermission = true;
        this.setState({updatePermission});
      }
    } else {
      this.props.history.push('/views/login');
    }
    
  }
  onClickUploadDocumentByModal = (show, createDocType) => {
    this.setShowDocumentModal(show);
    this.props.onClickUploadDocumentByModal(createDocType);
  }

  setShowDocumentModal =(show) => {
    this.setState({showDocumentModal: show});
  }

  setShowErrorMessageModal(show) {
    this.setState({showErrorMessageModal: show});
  }

  setShowLoadTemplate(show) {
    this.setState({showLoadTemplateModal: show});
  }

  setShowSaveTemplate(show) {
    this.setState({showSaveTemplateModal: show});
  }

  addRequiredDocIdHandler = (e) => {
    
  }

  preSetRequiredDocs = (numberOfRows) => {
    
  }

  setApprovalTemplate = (templateId) => {
    let approvalTemplates = this.state.approvalTemplates;
    console.log('inspect approvalTemplates: ');
    console.log(util.inspect(approvalTemplates));
    for (let i = 0; i < approvalTemplates.length; i++) {
      if (approvalTemplates[i]._id == templateId) {
        console.log('update approvalTemplate state');
        this.setState(prevState => ({
          ... prevState,
          approvalTemplate: approvalTemplates[i],
        }));
        // return;
      }
    }
  }

  validateOnSubmit = () => {
    var isValid = true;
    let {approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, summary} = this.state;

    var validateItemList = [];
  
    //TODO: Add items to be validated into this list
    validateItemList.push(approvalLevels);
    validateItemList.push(approvalNumber);
    validateItemList.push(priority);
    validateItemList.push(approvalName);
    validateItemList.push(supportDocs);
    validateItemList.push(approvalDoc);
    validateItemList.push(summary);

    for (var i = 0; i < validateItemList.length; i++) {
      if(!validateItemList[i]) {
        isValid = false;
        break;
      }
    }
    if (this.props.grainImageValidation != true) {
      isValid = false
    }

    return isValid;
  }

  onCreateOrUpdateApproval = (_state) => {

    if(!this.validateOnSubmit()) {
      this.setShowErrorMessageModal(true);
      return;
    }

    if (this.state.isUpdateApproval) {
      this.onSubmitUpdateApproval();
    } else {
      this.onSubmitCreateApproval(_state);
    }
  }

  onSubmitCreateApproval = (_state) => {
    let approval = {};
    let {approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, summary} = this.state;
    let currentUser = this.props.currentUser;
    let companyId = "5ecfa2ee02ca33324141568c";
    if (currentUser.company) {
      if (currentUser.company._id) {
        companyId = currentUser.company._id;
      } else {
        companyId = currentUser.company;
      }
    }
    // preset the first level as signing level
    approvalLevels[0].status = _state;
    approval.approvalNumber = approvalNumber;
    approval.priority = priority;
    approval.approvalName = approvalName;
    approval.company = companyId;
    approval.supportDocs = supportDocs;
    approval.approvalDoc = approvalDoc;
    approval.approvalLevels = approvalLevels;
    approval.summary = summary;
    approval.status = _state;
    // set deadline = deadline of last approvalLevels
    const _numberOfLevels = approvalLevels.length;
    if (_numberOfLevels > 0) {
      const _deadline = approvalLevels[_numberOfLevels - 1].deadline;
      approval.deadline = _deadline;
      console.log('approval from state: ', util.inspect(approval));
      // send to action
      this.props.onSubmitCreateApprovalAction(approval);
    }
    
  }

  onSubmitUpdateApproval = (_state) => {
    let approval = {};
    let {approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc} = this.state;
    // preset the first level as signing level
    let currentUser = this.props.currentUser;
    let companyId = "5ecfa2ee02ca33324141568c";
    if (currentUser.company) {
      if (currentUser.company._id) {
        companyId = currentUser.company._id;
      } else {
        companyId = currentUser.company;
      }
    }
    approvalLevels[0].status = _state;
    approval.approvalNumber = approvalNumber;
    approval.priority = priority;
    approval.approvalName = approvalName;
    approval.company = companyId;
    approval.supportDocs = supportDocs;
    approval.approvalDoc = approvalDoc;
    approval.approvalLevels = approvalLevels;
    approval._id = this.props.approval._id;
    approval.status = _state;
    console.log('approval from state: ', util.inspect(approval));
    // send to action
    this.props.onSubmitUpdateApprovalAction(approval, approvalLevels);
  }

  submitIssueApproval = () => {
    console.log('observe approval before issue ..');
    console.log(util.inspect(this.state.approval));
    let approvalId = this.state.approval._id;
    if ((approvalId !== undefined) && approvalId !== '') {
      console.log('issuing this approval ..');
      axios.post(`${url}company/approval/${approvalId}/issue`, {approvalId: approvalId}).then(res =>{
        console.log('res data: ');
        console.log(util.inspect(res.data));
      })
    } else {
      console.log('approval id not valid', approvalId);
    }
  }


  formatDate = (_today) => {
    // const _today = new Date();
    _today = new Date(_today);
    let _month = _today.getMonth() + 1;
    if (_month <= 9) {
      _month = '0' + _month.toString();
    }
    let _day = _today.getDate();
    if (_day <= 9) {
      _day = '0' + _day.toString();
    }
    let formatDate = _today.getFullYear() + '-' + _month + '-'  + _day;
    return formatDate;
  }

  onViewDocumentDetail = (document) => {
    console.log('user selected this Document: ');
    console.log(util.inspect(document));
    this.props.onViewDocument(document);
  }

  onRemoveMainDocument = (document) => {
    this.setState(prevState => ({
      ... prevState,
      approvalDoc: null,
    }))
    
  }

  onRemoveRelevantDocument = (document) => {
    let documents = this.state.supportDocs ? this.state.supportDocs : [];
    let _newDocument = []
    if (documents.length > 0) {
      documents.forEach((_row, _index) => {
        if (_row._id.toString() != document.toString()) {
          _newDocument.push(_row);
        }
      })
      this.setState(prevState => ({
        ... prevState,
        supportDocs: _newDocument,
      }))
    }
  }

  handleUploadingFarmDataHandler = (farmDataKeyValue) => {
    // const { number, grainType, harvestGrade, storageLocation, quantity, protein, moisture, productionDate } = farmData
    console.log('farmData selected is: ', farmDataKeyValue);
    let farmDatas = this.props.farmDatas ? this.props.farmDatas : [];
    let approvalDoc = this.state.approvalDoc;
    farmDatas.forEach((_row, _index) => {
      if (_row.number.toString() == farmDataKeyValue.value.toString()) {
        approvalDoc =_row;
        this.setState(prevState => ({
          ... prevState,
          approvalDoc: approvalDoc,
        }))
      }
    })
    
  }


  handleAddingRelevantDocument = (documentName) => {
    console.log('documentName selected is: ', documentName);
    let documents = this.props.documents ? this.props.documents : [];
    let _documents = this.state.supportDocs;
    documents.forEach((_row, _index) => {
      if (_row.docName.toString() == documentName.value.toString()) {
        _documents.push(_row);
      }
    })
    this.setState(prevState => ({
      ... prevState,
      supportDocs: _documents,
    }))
  }

  handleAddingSigner = (_invidualSignerInfo, level) => {
    console.log('Signer selected is: ', _invidualSignerInfo);
    let signers = this.props.signers ? this.props.signers : [];
    let approvalLevels = this.state.approvalLevels;
    let _levelSigners;
    approvalLevels.forEach((_row, _index) => {
      if (_row.level.toString() == level.toString()) {
        _levelSigners = _row.signers;
      }
    })
    signers.forEach((_row, _index) => {
      if (_row._id.toString() == _invidualSignerInfo.key.toString()) {
        _levelSigners.push(_row);
      }
    })
    approvalLevels.forEach((_row, _index) => {
      if (_row.level.toString() == level.toString()) {
        approvalLevels[_index].signers = _levelSigners;
      }
    })
    this.setState(prevState => ({
      ... prevState,
      approvalLevels: approvalLevels,
    }))
    
  }

  handleRemoveSigner = (_signerId, level) => {
    console.log('Signer to be removed: ', _signerId);
    // let signers = this.props.signers ? this.props.signers : [];
    let approvalLevels = this.state.approvalLevels;
    console.log('approvelLevels: ', util.inspect(approvalLevels));
    let _levelSigners;
    if (approvalLevels.length > 0) {
      approvalLevels.forEach((_row, _index) => {
        console.log('approveLevel of: ', util.inspect(_row));
        if (_row.level.toString() == level.toString()) {
          _levelSigners = _row.signers;
        }
      })
      let _newLevelSigners = [];
      _levelSigners.forEach((_row, _index) => {
        if (_row._id.toString() != _signerId.toString()) {
          _newLevelSigners.push(_row);
        }
      })
      approvalLevels.forEach((_row, _index) => {
        if (_row.level.toString() == level.toString()) {
          approvalLevels[_index].signers = _newLevelSigners;
        }
      })
      this.setState(prevState => ({
        ... prevState,
        approvalLevels: approvalLevels,
      }))
    }
    
  }

  onViewSignerDetail = (signer) => {
    // implemented later
    console.log('view signer of: ', util.inspect(signer));
  }

  onAddApprovalLevel = (e) => {
    let approvalLevels = this.state.approvalLevels;
    const _levels = approvalLevels.length;
    let newApprovalLevel =  {
      level: parseInt(_levels) + 1, 
      group: "Approval Level " + (parseInt(_levels) + 1).toString(), 
      signers: [],
      signatures: 1,
      deadline: this.formatDate(new Date()), 
      status: 'created', 
      signed: []
    };
    // {level: 2, group: "Phê duyệt cấp 2", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
    approvalLevels.push(newApprovalLevel);
    this.setState(prevState => ({
      ...prevState,
      approvalLevels: approvalLevels,
    }))

  }

  onRemoveApprovelLevel = (_index) => {
    let approvalLevels = this.state.approvalLevels;
    let newApprovalLevels = [];
    approvalLevels.forEach((_approvalLevel, __index) => {
      if (parseInt(_index) != parseInt(__index)){
        newApprovalLevels.push(_approvalLevel);
      }
    })
    this.setState(prevState => ({
      ...prevState,
      approvalLevels: newApprovalLevels,
    }))
  }

  removeDuplicate = (_array) => {
    console.log('start remvoe duplicate of array: ', util.inspect(_array));
    let uniqueArray = [];
    let _keys = []
    _array.forEach((_obj, _index) => {
      if (!_keys.includes(_obj._id.toString())) {
        uniqueArray.push(_obj);
        _keys.push(_obj._id.toString());
      }
    })
    return uniqueArray;
  }

  getGrainImageValidation = (farmData) => {
    console.log(farmData)
    if (farmData.imageUrl.toString() != "localhost") {
      
      this.props.onGetGrainImageValidation(farmData);
    }
  }

  renderMainDocumentTableData() {
    console.log('list of documents:');
    console.log(util.inspect(this.props.documents));
    let documents = [];
    if (this.state.approvalDoc) {
      documents = [this.state.approvalDoc];
    } else if (this.props.approvalDoc) {
      documents = documents.push(this.props.approvalDoc);
      // this.setState({approvalDoc: documents})
    }
    if (!documents) {
      return (
        null
      )
    }
    
    if (documents.length > 0) {
      // remove duplicate
      // documents = this.removeDuplicate(documents);
      return documents.map((document, index) => {
        const { _id, description, docName, uploadUser, uploadDate } = document //destructuring
        // const { _id, docName, docType, uploadUser, uploadDate } = document
        let uploadUserName = uploadUser;
        if( uploadUser) {
          if (uploadUser._id) {
            uploadUserName = uploadUser.firstname + ' ' + uploadUser.lastname;
          }
        }
        let item = parseInt(index + 1)
        return (
            <Tr key={item}>
              <Td>{item ? item.toString() : ''}</Td>
              <Td>{docName ? docName : ''}</Td>
              <Td>{description ? description : ''}</Td>
              <Td paddingLeft="0px" align="right">
                <ViewButton
                  to="/views/document/detail" 
                  target="_blank"
                  onClick = {() => {
                    this.onViewDocumentDetail(document);
                    // window.open(this.makeHref("/views/document/detail"));
                  }}
              >View</ViewButton></Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                onClick = {() => {
                  this.onRemoveMainDocument(_id)
                }}
              >X</RemoveButton></Td>
            </Tr>
        )
      })
    } else {
      return (
        null
      )
    }
    
  }

  renderRelevantDocumentTableData() {
    console.log('list of documents:');
    console.log(util.inspect(this.props.documents));
    let documents = [];
    
    if (this.state.supportDocs) {
      documents = this.state.supportDocs;
    }
    if (this.props.supportDoc) {
      documents.push(this.props.supportDoc);
      // this.setState({supportDocs: documents});
    }
    
    if (!documents) {
      return (
        null
      )
    }
    if (documents.length > 0) {
      // remove dupplicate
      // remove duplicate
      // documents = this.removeDuplicate(documents);
      return documents.map((document, index) => {
        const { _id, description, docName, uploadUser, uploadDate } = document //destructuring
        let uploadUserName = uploadUser;
        if( uploadUser) {
          if (uploadUser._id) {
            uploadUserName = uploadUser.firstname + ' ' + uploadUser.lastname;
          }
        }
        let item = parseInt(index + 1)
        return (
            <Tr key={item}>
              <Td>{item ? item.toString() : ''}</Td>
              <Td>{docName ? docName : ''}</Td>
              <Td>{description ? description : ''}</Td>
              <Td paddingLeft="0px" align="right">
                <ViewButton
                  to="/views/document/detail" 
                  target="_blank"
                  onClick = {() => {
                    this.onViewDocumentDetail(document);
                    // window.open(this.makeHref("/views/document/detail"));
                  }}
              >View</ViewButton></Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                onClick = {() => {
                  this.onRemoveRelevantDocument(_id)
                }}
              >X</RemoveButton></Td>
            </Tr>
        )
      })
    } else {
      return (
        null
      )
    }
  }

  renderSignerInfoTable(level) {
    console.log('list of documents:');
    console.log(util.inspect(this.props.signers));
    let approvalLevels = this.state.approvalLevels;
    let _levelSigners = [];
    approvalLevels.forEach((_row, _index) => {
      if (_row.level.toString() == level.toString()) {
        _levelSigners = _row.signers;
      }
    })
    if (!_levelSigners) {
      return (
        null
      )
    }
    if (_levelSigners.length > 0) {
      return _levelSigners.map((_signerInfo, _index) => {
        let { _id, fullName, role, firstname, lastname} = _signerInfo //destructuring
        let __index = parseInt(_index) + 1;
        if (firstname) {
          fullName = firstname + ' ' + lastname
        }
        return (
            <Tr key={_id}>
              <Td>{__index ? __index.toString() : ''}</Td>
              <Td>{fullName ? fullName : ''}</Td>
              <Td>{role ? role : ''}</Td>
              <Td paddingLeft="0px" align="right">
                <ViewButton
                  onClick = {() => {
                    this.onViewSignerDetail(_signerInfo)
                  }}
              >Profile</ViewButton></Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                onClick = {() => {
                  this.handleRemoveSigner(_id, level)
                }}
              >X</RemoveButton></Td>
            </Tr>
        )
      })
    } else {
      return (
        null
      )
    }
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

  renderApprovalLevelTable() {
  
    let approvalLevels = this.state.approvalLevels;
    
    return approvalLevels.map((_approvalLevel, _index) => {
      const { level, group, signatures, deadline, status } = _approvalLevel //destructuring
      let approvalLevelGroup = group.toString()
      return (
        <ApproveLevelContainer key={level}>
          <TitleContainer>
            <ApprovalIcon />
            <Title>{approvalLevelGroup}</Title>
            <RemoveApprovalIcon 
              onClick={() => this.onRemoveApprovelLevel(_index)}
            />
          </TitleContainer>
          
          <ApprovalContentContainer>
            <InfoContainer>
              <Label flexGrow={1}>Name (*)</Label>
              <Input inputType={'text'}
                value={group} 
                placeholder = {'Enter name of approval level'}
                onChange = {(e) => {
                  let group = e.target.value;
                  approvalLevels[_index].group = group
                  this.setState(prevState => ({
                    ... prevState,
                    approvalLevels: approvalLevels,
                  }));
                }} 
              />
            </InfoContainer>
            <InfoContainer>
              <Label>Due Date (*)</Label>
              <Input inputType={'text'}
                  value={deadline} 
                  placeholder = {'yyyy-mm-dd'}
                  onChange = {(e) => {
                    approvalLevels[_index].deadline = e.target.value;
                    this.setState(prevState => ({
                      ... prevState,
                      approvalLevels: approvalLevels,
                    }));
                  }}
                  // style={inputStyle}                   
              /> 
            </InfoContainer>
            <InfoContainer>
              <Label>Number of Signatures (*)</Label>
              <Select 
              value={this.state.approvalLevels.signatures} 
              onChange={(e) => {
                approvalLevels[_index].signatures = e.target.value;
                this.setState(prevState => ({
                  ... prevState,
                  approvalLevels: approvalLevels,
                }));
              }}
              width="150px"
            >
              {[1,2,3,4,5,6,7,8,9,10].map(_number => {
              return (
                <Option
                  selected={parseInt(_number) === parseInt(signatures) ? true : false}
                  value={_number}>
                    {_number + ' signature(s)'}
                </Option>
              );
            })}
            </Select>
            </InfoContainer>
            <InfoContainer>
              <Label>Approver (*)</Label>
              <SearchboxContainer flexGrow={1}>
                <ReactSearchBox
                  placeholder="Add approvers"
                  value=""
                  data={this.props.signersInfo}
                  callback={(record )=> {
                    console.log(record);
                  }}
                  // onChange={(e) => {
                  //   let {searchValue} = this.state;
                  //   searchValue = e.target.value;
                  //   this.setState(searchValue);
                  // }}
                  onSelect={(_invidualSignerInfo) => {
                    this.handleAddingSigner(_invidualSignerInfo, level)
                  }}
                  onFocus={() => {
                    console.log('This function is called when is focussed')
                  }}
                  onChange={value => console.log(value)}
                  fuseConfigs={{
                    threshold: 0.05,
                  }}
                />
              </SearchboxContainer>
              
            </InfoContainer>
            
            <Table>
              <THead>
                <Tr>
                  <Th>#</Th>
                  <Th>Approver</Th>
                  <Th>Position</Th>
                  <Th>View</Th>
                  <Th>Remove</Th>
                </Tr>
              </THead>
                
              <TBody>
                {this.renderSignerInfoTable(level)}
              </TBody>
            </Table>
          </ApprovalContentContainer>
          
        </ApproveLevelContainer>
      )
    })
  }

  renderSigningButtons = () => {
    if (this.props.signingPermission == true) {
      return (
        <SigingButtonBox>
          <GreenButton onClick={() => this.props.onSignApproval()}>
            {'Sign and pass'}
          </GreenButton>
          <RedButton onClick={() => this.props.onRejectApproval()}>
            {'Reject'}
          </RedButton>
        </SigingButtonBox>
      )
    }
  }

  renderUpdateButtons = () => {
    if (this.state.updatePermission == true) {
      return (
        <SigingButtonBox>
          <BlueButton onClick={() => this.onCreateOrUpdateApproval('signing')}>
            {'Submit'}
          </BlueButton>
          <GrayBorderButton onClick={() => this.onCreateOrUpdateApproval('created')}>
            {'Save'}
          </GrayBorderButton>
          <GrayBorderButton onClick={() => this.props.onDeleteApproval()}>
            {'Cancel'}
          </GrayBorderButton>
        </SigingButtonBox>
      )
    }
  }

  onLoadTemplate = () => {
    console.log('Load a template...');
    this.setShowLoadTemplate(true);
  }

  onSaveTemplate = () => {
    console.log('Save a template...');
    this.setShowSaveTemplate(true);
  }

  renderLeftHeader = () => {
    return (
      <LeftHeader>
        <FormTitle>{"New Grain Quality Certificate"}</FormTitle>
        <TemplateSection>
          <TemplateName><BoldLabel>Template:</BoldLabel> {"Grain Quality Certfificate V1.0"}</TemplateName>
          <CustomLink onClick = {() => this.onLoadTemplate()}>
            [ Load a template ]
          </CustomLink>
          <CustomLink onClick = {() => this.onSaveTemplate()}>
            [ Save as a template ]
          </CustomLink>
        </TemplateSection>
      </LeftHeader>
    )
  }

  renderFarmDataTableData() {

    console.log(util.inspect(this.state.approvalDoc));
    let farmDatas = [];
    let approvalDoc = this.state.approvalDoc;
    if (approvalDoc) {
      farmDatas = [approvalDoc]
    }
    if (farmDatas.length > 0) {
      return farmDatas.map((farmData, index) => {
          const { number, grainType, harvestGrade, storageLocation, quantity, protein, moisture, productionDate } = farmData //destructuring
          let _grainQuality = "APH";
          if (protein >= 90) {
            _grainQuality = "APH";
          } else if (protein >= 80) {
            _grainQuality = "AP";
          } else if (protein >= 70) {
            _grainQuality = "APW";
          } else if (protein >= 60) {
            _grainQuality = "ASW";
          } else if (protein >= 50) {
            _grainQuality = "ADR";
          } else if (protein >= 40) {
            _grainQuality = "ANW";
          } else if (protein >= 30) {
            _grainQuality = "ASFT";
          } else if (protein >= 20) {
            _grainQuality = "APWN";
          } else {
            _grainQuality = "FEED";
          }
          
          // let _metaData = JSON.parse(metaData);
          let _item = parseInt(index) + 1;
          return (
              <Tr key={_item}>
                <Td>{_grainQuality ? _grainQuality.toString() : ''}</Td>
                <Td>{number ? number.toString() : ''}</Td>
                <Td>{grainType ? grainType : ''}</Td>
                <Td>{harvestGrade ? harvestGrade : ''}</Td>
                <Td>{storageLocation ? storageLocation : ''}</Td>
                <Td>{quantity ? quantity.toFixed(0) : ''}</Td>
                <Td>{protein ? protein.toFixed(2) : ''}</Td>
                <Td>{moisture ? moisture.toFixed(2) : ''}</Td>
                <Td>{productionDate ? formatDate(productionDate) : ''}</Td>
                
              </Tr>
          )
      })
      
    } else {
      return (
        <div>loading ..</div>
      )
    }
  }

  renderCheckSign = (_isRenderCheckSign) => {
    if (_isRenderCheckSign) {
      return (
        <CheckSquareIcon/>
      )
    }
  }

  body = () => {
    return (
      <ContentContainer>
        <FormContainer>
          <FormHeader>
            {this.renderLeftHeader()}
            {this.renderUpdateButtons()}
            {this.renderSigningButtons()}
          </FormHeader>
          
          <ComponentContainer>
            
            <InfoContainer>
              <Label>Certfificate Number (*)</Label>
              <Input inputType={'text'}
                  value={this.state.approvalNumber} 
                  placeholder = {'Enter Grain Quality Certification Number'}
                  onChange = {(e) => {
                    let approvalNumber = e.target.value;
                    this.setState(prevState => ({
                      ... prevState,
                      approvalNumber: approvalNumber,
                    }));
                  }}
                  // style={inputStyle}                   
                  /> 
            </InfoContainer>
            <InfoContainer>
            <Label>Certfificate Name  (*)</Label>
              <Input inputType={'text'}
                  value={this.state.approvalName} 
                  placeholder = {'Enter approval name'}
                  onChange = {(e) => {
                    let approvalName = e.target.value;
                    this.setState(prevState => ({
                      ... prevState,
                      approvalName: approvalName,
                    }));
                  }}
                  // style={inputStyle}                   
                  /> 
            </InfoContainer>
            {/* <InfoContainer>
              <Label>Priority (*)</Label>
              <Select 
                value={this.state.priority} 
                onChange={(e) => {
                  let {priority} = this.state;
                  priority = e.target.value;
                  this.setState(prevState => ({
                    ... prevState,
                    priority: priority,
                  }));
                }}
              >
                {['High', 'Medium', 'Low'].map(_priority => {
                  return (
                    <Option
                      selected={_priority === this.state.priority ? true : false}
                      value={_priority}>
                        {'Priority: ' + _priority}
                    </Option>
                  );
                })}
              </Select>
            </InfoContainer> */}
            
            <InfoContainer>
              <Label width="135px">Farm Data Upload</Label>
              
              <ControlerRow>
                <ControlerCol lg>
                  <SearchBoxContainer>
                    <ReSearchBox
                      placeholder="Search for farm data record ..."
                      value=""
                      data={this.props.farmDataKeyValues}
                      callback={(record )=> {
                        console.log(record);
                      }}
                      // onChange={(e) => {
                      //   let {searchValue} = this.state;
                      //   searchValue = e.target.value;
                      //   this.setState(searchValue);
                      // }}
                      onSelect={(farmDataKeyValue) => {
                        this.handleUploadingFarmDataHandler(farmDataKeyValue)
                      }}
                      onFocus={() => {
                        console.log('This function is called when is focussed')
                      }}
                      onChange={value => console.log(value)}
                      fuseConfigs={{
                        threshold: 0.05,
                      }}
                    />
                  </SearchBoxContainer>

                </ControlerCol>
              </ControlerRow>
              <Table>
                <THead>
                  <Tr>
                    <Th>Grain Quality</Th>
                    <Th>Number</Th>
                    <Th>Grain Type</Th>
                    <Th>Havest Grade</Th>
                    <Th>Storage Location</Th>
                    <Th>Quantity</Th>
                    <Th>Protein</Th>
                    <Th>Moisture</Th>
                    <Th>Production Date</Th>
                  </Tr>
                </THead>
                
                <TBody>
                  {this.renderFarmDataTableData()}
                </TBody>
              </Table>
            </InfoContainer>

            <InfoContainer>
              <Label width="135px">Related Document</Label>
              <ControlerRow>
                <ControlerCol lg>
                  <SearchBoxContainer>
                    <ReSearchBox
                      placeholder="Search for related documents"
                      value=""
                      data={this.props.documentNames}
                      callback={(record )=> {
                        console.log(record);
                      }}
                      // onChange={(e) => {
                      //   let {searchValue} = this.state;
                      //   searchValue = e.target.value;
                      //   this.setState(searchValue);
                      // }}
                      onSelect={(documentName) => {
                        this.handleAddingRelevantDocument(documentName)
                      }}
                      onFocus={() => {
                        console.log('This function is called when is focussed')
                      }}
                      onChange={value => console.log(value)}
                      fuseConfigs={{
                        threshold: 0.05,
                      }}
                    />
                  </SearchBoxContainer>
                  
                  <GrayBorderButton
                    onClick={() => this.onClickUploadDocumentByModal(true, 'supportDoc')}
                  >Attach File</GrayBorderButton>
                </ControlerCol>
              </ControlerRow>
              <Table>
                <THead>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Number</Th>
                    <Th>Name</Th>
                    <Th>View</Th>
                    <Th>Remove</Th>
                  </Tr>
                </THead>
                  
                <TBody>
                  {this.renderRelevantDocumentTableData()}
                </TBody>

              </Table>
            </InfoContainer>
            <InfoContainer>
            <Label>{"Certificate Validation"}</Label>

            <Table>
              <THead>
                <Tr>
                  <Th>Item</Th>
                  <Th>description</Th>
                  <Th>Check Status</Th>
                  <Th>Actions</Th>
                </Tr>
              </THead>
                
              <TBody>
                {/* <Tr key={1}>
                  <Td>{1}</Td>
                  <Td>{"Grain Quality"}</Td>
                  <Td>{this.state.approvalDoc ? "X" : ''}</Td>
                </Tr> */}
                {/* three status */}
                <Tr key={1}>
                  <Td>{1}</Td>
                  <Td>{"Protein"}</Td>
                  <Td>{this.renderCheckSign(this.state.approvalDoc)}</Td>
                  <Td>{""}</Td>
                </Tr>
                <Tr key={2}>
                  <Td>{2}</Td>
                  <Td>{"Moinsture"}</Td>
                  <Td>{this.renderCheckSign(this.state.approvalDoc)}</Td>
                  <Td>{""}</Td>
                </Tr>

                <Tr key={3}>
                  <Td>{3}</Td>
                  <Td>{"Harvest Grade"}</Td>
                  <Td>{this.renderCheckSign(this.state.approvalDoc)}</Td>
                  <Td>{""}</Td>
                </Tr>

                <Tr key={4}>
                  <Td>{4}</Td>
                  <Td>{"Grain Image Validation"}</Td>
                  <Td>{this.renderCheckSign(1)}</Td>
                  <Td>{""}</Td>
                  {/* <Td>{this.renderCheckSign(this.props.grainImageValidation)}</Td>
                  <Td>{(this.props.grainImageValidation) ? this.renderCheckSign(1) : <AddButton
                    onClick={() => this.getGrainImageValidation(this.state.approvalDoc)}
                  >{"Get Validation"}</AddButton>}</Td> */}
                </Tr>

                <Tr key={5}>
                  <Td>{5}</Td>
                  <Td>{"Production Lanscap Image"}</Td>
                  <Td>{(this.state.supportDocs.length >= 1) ? this.renderCheckSign(1) : ''}</Td>
                  <Td>{(this.state.supportDocs.length < 1) ? "Upload Landscap Doc" : ''}</Td>
                </Tr>
                <Tr key={6}>
                  <Td>{6}</Td>
                  <Td>{"Production History Record"}</Td>
                  <Td>{(this.state.supportDocs.length >= 2) ? this.renderCheckSign(1) : ''}</Td>
                  <Td>{(this.state.supportDocs.length < 2) ? "Upload History Record" : ''}</Td>
                </Tr>
              </TBody>

            </Table>

            </InfoContainer>
            {this.renderApprovalLevelTable()}
            <InputContainer>
              <BlueBorderButton
                onClick = {(e) => {
                  this.onAddApprovalLevel(e)
                }}
              >+ More Level</BlueBorderButton>
              {this.renderUpdateButtons()}
            </InputContainer>
          </ComponentContainer>
          
        </FormContainer>
        {/* Document modal */}
        <DocumnetModalForm 
          showDocumentModal={this.props.showDocumentModal ? this.props.showDocumentModal : this.state.showDocumentModal} 
          hideDocumentModalAction={() => this.setShowDocumentModal(this.props.hideDocumentModal ? true : false)} 
        />

        <ErrorMessageModal 
          showErrorMessageModal={this.state.showErrorMessageModal} 
          hideErrorMessageModal={() => this.setShowErrorMessageModal(false)} 
        />

        <LoadTemplateModal 
          showLoadTemplateModal={this.state.showLoadTemplateModal} 
          hideTemplateModal={() => this.setShowLoadTemplate(false)} 
        />

        <SaveTemplateModal 
          showSaveTemplateModal={this.state.showSaveTemplateModal} 
          hideTemplateModal={() => this.setShowSaveTemplate(false)} 
        />
      </ContentContainer>
    );
  }
  render() {
    return (
      <ApprovalBase
        contentContainer={this.body()}
        selectMenuItem="3"
        onCreateOrUpdateApproval={() => this.submitCreateApproval()}
        onIssueApproval={this.submitIssueApproval}
        approvalTemplates={this.props.approvalTemplates ? this.props.approvalTemplates : []}
      />
    )
  }
}

const mapStateToProps = state => {
  // console.log('documents received from reducer:');
  // console.log(util.inspect(state.document.documents));
  console.log('grainImageValidation from reducer:');
  console.log(util.inspect(state.approvalState.grainImageValidation));
  return {
    company: state.company.company,
    signers: state.fetchData.signers,
    signersInfo: state.fetchData.signersInfo,
    approvalNumber: state.approvalState.approvalNumber,
    currentUser: state.authState.currentUser,
    approval: state.approvalState.approval,
    signer: state.fetchData.signer,
    // approvalTemplate: state.fetchData.approvalTemplate,
    // approvalTemplates: state.approvalState.approvalTemplates,
    documentNames: state.document.documentNames,
    documents: state.document.documents,
    showDocumentModal: state.document.showDocumentModal,
    approvalDoc: state.approvalState.approvalDoc,
    supportDoc: state.approvalState.supportDoc,
    farmDatas: state.farmData.farmDatas,
    farmDataKeyValues: state.farmData.farmDataKeyValues,
    grainImageValidation: state.approvalState.grainImageValidation,
  }
}
  
  const mapDispatchToProps = dispatch => {
    return {
        // onNewEvent: () => dispatch(lastEventAction()),
        onFetchCompany: (companyId) => dispatch(fetchCompany(companyId)),
        onFetchSigners: () => dispatch(fetchSigners()),
        onFetchSigner: (signerId) => dispatch(fetchSigner(signerId)),
        // onFetchApprovalNumber: () => dispatch(fetchGetApprovalCreate()),
        // onFetchApprovalTemplates: () => dispatch(fetchApprovalTemplates()),
        onGetCreateApproval: () => dispatch(getCreateApproval()),
        onFetchDocuments: () => dispatch(fetchDocuments()),
        onSubmitCreateApprovalAction: (approval) => dispatch(createApprovalAction(approval)),
        onSubmitUpdateApprovalAction: (approval, approvalLevels) => dispatch(updateApprovalAction(approval, approvalLevels)),
        onClickUploadDocumentByModal: (createDocType) => dispatch(onClickUploadDocumentByModal(createDocType)),
        onViewDocument: (document) => dispatch(selectViewDocumentAction(document)),
        onFetchFarmDatas: () => dispatch(fetchFarmDatas()),
        onGetGrainImageValidation: (farmData) => dispatch(getGrainImageValidationAction(farmData))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
  
// helpers
function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
/*********************Start Stylesheet**********************/
