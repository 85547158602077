import React from 'react'
import { connect } from 'react-redux';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';

import {
    CancelButton, XIcon, RedButton
} from './style';


export default class ErrorMessageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        
    }

    render() {
        return (
            <Modal
            show={this.props.showErrorMessageModal}
            onHide={() => this.props.hideErrorMessageModal()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <XIcon />All fields with (*) are required. Please fill them all before submitting.
            </Modal.Body>
            <Modal.Footer>
                <RedButton onClick={() => this.props.hideErrorMessageModal()}>OK</RedButton>
            </Modal.Footer>
        </Modal>
        );
    }
}
