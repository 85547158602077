import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import CSVReader from 'react-csv-reader';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import FarmDataBase from './FarmDataBase';
// import { authenticationService, fetchData } from '../../services';
import {
  emailLogoutAction,
  createFarmDataAction,
  createStreamingFarmDataAction,
  fetchAutoGeneratedFarmData,
} from '../../store/actions';

import {
  SpanLabel,
} from '../../commons';
import {
  InfoContainer, FormHeader, Title, BackButton, LeftArrow, InnerContainer, Tr, Td, TableContainer, Table, THead, Th, TBody, Button,
  FormContainer, FormBox, Label, TextInfo, GreenButton, Input
} from './style';
import {
  formControl
} from '../../langueges';

import { history } from '../../App';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
const {
  nameValidate,
  farmDataCodeValidate,
  farmDataNameValidate,
  farmDataUnitValidate,
  farmDataQuantityValidate,
  farmDataPriceValidate,
  floatValidate,
  quantityValidate
} = require('../../services/validate');

class FarmDataUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        height: window.innerHeight - 112,
        user: this.props.currentUser,
        farmDatas: [],
        editPermission: true,
        validation: false,
        grainTypes: ['Categorical', 'Wheat', 'Barley', 'Corn'],
        harvestGrades: ['APH', 'AH', 'APW', 'ASW', 'ADR', 'AMW', 'ASFT', 'APWN', 'FEED'],
        streaming: false,
        streamingInterval: 10,
        seconds: 0,
        invalidMsg: "",
    };


  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    if (this.props.currentUser) {
      this.setState( prevState => ({ 
          ...prevState,
          user: this.props.currentUser,
        }))
      if (this.props.farmData) {
        console.log('this is an existing farmData for update');
        let {farmData} = this.state;
        farmData = this.props.farmData;
        this.setState({existingCheck: true, farmData});
      }
    } else {
        return emailLogoutAction();
    }

    this.interval = setInterval(() => this.tick(), 1000);
    
  }

  // fetch farm data every 5 seconds
  tick() {
    
    if (this.state.streaming) {
      console.log('counting ..');
      this.setState(state => ({
        seconds: state.seconds + 1,
      }));
      let intervalValidate = quantityValidate(this.props.language, this.state.streamingInterval);
      if (intervalValidate == "") {
        console.log('interval validation ok');
        let streamingInterval = parseFloat(this.state.streamingInterval);
        if (!streamingInterval) {
          streamingInterval = 10;
        } 
        streamingInterval = parseInt(streamingInterval);
        if ((this.state.seconds) % 10 === 1) {
          console.log('time to fetch realtime watchlist');
          this.props.onFetchAutoGeneratedFarmData();   
          let newFarmData = this.props.farmData;
          if (newFarmData) {
            newFarmData.status = "Saving ..";
            let farmDatas = this.state.farmDatas;
            if (farmDatas.length > 0) {
              farmDatas[0].status = "Saved";
            } 
            farmDatas.unshift(newFarmData);
            this.setState({
              farmDatas: farmDatas,
            })
            this.saveStreamingDataHandler(newFarmData);
          } else {
            console.log('farm data is null.., nothing to save..');
          }
          // this.setState(prevState => ({
          //   ... prevState,
          //   stocks: this.props.stocks,
          // }))
        }
        // this.props.onFetchWatchList(this.state.labels);   
        // this.setState(prevState => ({
        //   ... prevState,
        //   stocks: this.props.stocks,
        // }))
        
      } else {
        console.log('interal validattion failed, ')
        this.setState({
          invalidMsg: intervalValidate,
        })
        return;
      }
    }
  }

  

  saveStreamingDataHandler = (_farmData) => {
    console.log('saving farm data for ', _farmData)
    console.log('saving streaming data start ..')
    if (this.state.invalidMsg != '') {
      // this.setState({invalidMsg: selectLanguage(this.props.language, farmDataLanguage.streamingFileErrorMsg)});
      console.log('invalid msg: ', this.state.invalidMsg);
      return
    }
    let _streamingFarmData = _farmData;
    let user = this.props.currentUser;
    let validateResult = true;
    if (this.onSubmitValidation(_streamingFarmData) == false) {
      validateResult = false;
    }
  
    if (validateResult === false) {
      console.log('validation farmData failed');
      return;
    }

    _streamingFarmData.stage = "Harvest";
    _streamingFarmData.modifiedDate = new Date();
    _streamingFarmData.productionDate = new Date(_streamingFarmData.productionDate);
    _streamingFarmData.streamingUser = user._id;
    console.log('submiting farm data for: ', _streamingFarmData);
    this.props.onCreateFarmData(_streamingFarmData)

  }

  onSubmitValidation = (farmData) => {
    let result = true;
    return result
    if (farmDataQuantityValidate(this.props.language, farmData.quantity) != "") {
      this.setState({invalidMsg: "Quantity must be an integer"});
      result = false;
    }
    if (floatValidate(this.props.language, farmData.moisture) != "") {
      this.setState({invalidMsg: "Moistrue must be a float"});
      result = false;
    }
    if (farmDataUnitValidate(this.props.language, farmData.protein) != "") {
      this.setState({invalidMsg: "Protein must be a float"});
      result = false;
    }
    return result;
  }


  startStreamingFarmData = () => {
    console.log('user select to start streaming data ..')
    if (this.state.streaming == false) {
      this.setState({
        streaming: true
      })
    } else {
      this.setState({
        streaming: false
      })
    }
  }



  // grainType: 'Wheat',
  //       harvestGrade: 'APH',
  //       storageLocation: '',
  //       quantity: '',
  //       protein: '',
  //       moisture: '',
  //       streamingUser: '',
  //       productionDate: new Date(),
  //       modifiedDate: new Date(),
  renderTableData() {
    return (this.state.farmDatas ? this.state.farmDatas : []).map((farmData, index) => {
      const { grainType, harvestGrade, storageLocation,quantity, protein, moisture, productionDate, status } = farmData //destructuring
      let item = (index + 1)
      return (
        <Tr 
          key={item}
          // onClick={() => this.props.onViewFarmDataDetail(farmData)}
        >
            <Td>{item.toString()}</Td>
            <Td>{grainType}</Td>
            <Td>{harvestGrade}</Td>
            <Td>{storageLocation}</Td>
            <Td>{quantity}</Td>
            <Td>{protein}</Td>
            <Td>{moisture}</Td>
            <Td>{productionDate ? formatDate(productionDate)  : ''}</Td>
            <Td>{status ? status  : 'saving ..'}</Td>
            {/* <Td paddingLeft="0px" align="right">
                <YellowButton onClick={() => this.updateFarmData(farmData)} data-id={_id}>
                    {selectLanguage(this.props.language, farmDataLanguage.updateFarmData)}
                </YellowButton>
            </Td> */}
        </Tr>
      )
    })
  }

  body() {
    var papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          // .toLowerCase()
          .replace(/\W/g, '_')
    }
    return (
        <InfoContainer>
          <FormHeader>
              <Title>{"Streaming Real-time Farm Data (Harvesting Activities)"}</Title>
              <BackButton to='/views/farmdata/harvesting/list'><LeftArrow />{"Back"}</BackButton>
          </FormHeader>
          <InnerContainer>
            <FormContainer>
              {
                (this.state.invalidMsg != '') ? 
                <SpanLabel 
                  text={this.state.invalidMsg}
                  style={{color: 'red'}}
                /> : null
              }
              <FormBox>
                <Label>{"Streaming Interval (seconds): "}</Label>
                <Input 
                  title={'Streaming Interval '}
                  className={'form-control'}
                  placeholder={'Please enter interval'}
                  value={this.state.streamingInterval } 
                  onChange={(e) => {
                    let {streamingInterval} = this.state;
                    streamingInterval = e.target.value;
                    this.setState({streamingInterval});
                  }}
                />
                <GreenButton 
                  width="200px"
                  onClick={() => this.startStreamingFarmData()}>
                    {this.state.streaming ? "Stop streaming" : "Start streaming"}
                </GreenButton>
              </FormBox>

            </FormContainer>
          </InnerContainer>
          <TableContainer>
              <Table>
                <THead>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Grain Type</Th>
                    <Th>Havest Grade</Th>
                    <Th>Storage Location</Th>
                    <Th>Quantity</Th>
                    <Th>Protein</Th>
                    <Th>Moisture</Th>
                    <Th>FarmDataion Date</Th>
                    <Th>Save Status</Th>
                  </Tr>
                </THead>
                
                <TBody>
                  {this.renderTableData()}
                </TBody>
              </Table>
            </TableContainer>
        </InfoContainer>
    );
  }

  render() {
    return (
      <FarmDataBase 
        contentContainer = {this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('check if farmDatae receive from reducer: ', state.farmData.autoGeneratedFarmData);

  return {
    currentUser: state.authState.currentUser,
    farmData: state.farmData.autoGeneratedFarmData,
    language: state.topMenu.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // onUploadFarmDatas: (farmDatas) => dispatch(streamingFarmDatas(farmDatas)),
    onCreateFarmData: (farmData) => dispatch(createStreamingFarmDataAction(farmData)),
    onFetchAutoGeneratedFarmData: () => dispatch(fetchAutoGeneratedFarmData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmDataUpload);


/*********************End Stylesheet**********************/


function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}